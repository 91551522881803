const axios = require("axios");

const ApiService = ({ version = 1 } = {}) => {
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/${version}`,
    timeout: 5000,
  });
};

export { ApiService };
