<style lang="scss" scoped>
.opacity-enter-active {
  animation: fade 0.25s;
}
.opacity-leave-active {
  animation: fade 0.25s reverse;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<template>
  <div class="absolute top-0 right-0 z-10 md-left-auto mx-10 my-10">
    <SettingsButton @click="shouldOpenModal = !shouldOpenModal" />
    <transition name="opacity">
      <SettingsModal
        key="settings-modal"
        :should-open="shouldOpenModal"
        v-if="shouldOpenModal"
        @settings:modal:close="shouldOpenModal = !shouldOpenModal"
      />
    </transition>
  </div>
</template>

<script>
import SettingsButton from "@/components/crypto/settings/button.vue";
import SettingsModal from "@/components/crypto/settings/modal.vue";

export default {
  components: {
    SettingsButton,
    SettingsModal,
  },
  data() {
    return {
      shouldOpenModal: false,
    };
  },
};
</script>
