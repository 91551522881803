const useSettings = (store) => {
  const ALLOW_SOUND_NOTIIFICATION = "MAVENFLOW_ALLOW_SOUND_NOTIFICATION";
  const AMOUNT_TO_ALERT = "MAVENFLOW_MIN_THRESHOLD";

  const getSettingsFromLocalStorage = () => {
    if (!localStorage.getItem(ALLOW_SOUND_NOTIIFICATION)) {
      localStorage.setItem(ALLOW_SOUND_NOTIIFICATION, true);
      store.commit("crypto/SET_ALLOW_SOUND_NOTIFICATION", {
        allow: true,
      });
    } else {
      store.commit("crypto/SET_ALLOW_SOUND_NOTIFICATION", {
        allow: localStorage.getItem(ALLOW_SOUND_NOTIIFICATION) === "true",
      });
    }

    if (!localStorage.getItem(AMOUNT_TO_ALERT)) {
      localStorage.setItem(AMOUNT_TO_ALERT, 500);
      store.commit("crypto/SET_AMOUNT_TO_ALERT", { amount: 500 });
    } else {
      store.commit("crypto/SET_AMOUNT_TO_ALERT", {
        amount: +localStorage.getItem(AMOUNT_TO_ALERT),
      });
    }
  };

  const setSettingsToLocalStorage = (allow, amount) => {
    localStorage.setItem(ALLOW_SOUND_NOTIIFICATION, allow);
    localStorage.setItem(AMOUNT_TO_ALERT, amount);
  };
  return { getSettingsFromLocalStorage, setSettingsToLocalStorage };
};

export { useSettings };
