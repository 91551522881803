import { ref } from "vue";

const useCryptoStreamBlock = () => {
  const retry = ref(0);

  const createEventSourceClient = (store) => {
    const url = `${process.env.VUE_APP_API_URL}/1/crypto/stream/live/block`;
    const es = new EventSource(url);

    es.onopen = function () {
      console.log("Stream status: " + es.readyState);
    };
    es.addEventListener("crypto:live:last-block", (e) => {
      store.commit("crypto/SET_LAST_BLOCK", {
        block: JSON.parse(e.data),
      });
    });
    es.onerror = () => {
      retry.value <= 4 ? (retry.value += 1) : es.close();
    };
  };
  return { createEventSourceClient };
};

export { useCryptoStreamBlock };
