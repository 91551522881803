import { ref } from "vue";

const useCryptoStream = () => {
  const retry = ref(0);

  const createEventSourceClient = (store) => {
    const url = `${process.env.VUE_APP_API_URL}/1/crypto/stream/live`;
    const es = new EventSource(url);

    es.onopen = function () {
      console.log("Stream status: " + es.readyState);
    };
    // es.onmessage = (e) => {
    //   console.log(e);
    // };
    es.addEventListener("crypto:live", (e) => {
      // console.log(store);
      store.dispatch("crypto/alert", {
        transaction: e.data,
      });
    });
    // es.addEventListener("keep-alive", (e) => {
    //   console.log(e);
    // });
    es.onerror = () => {
      retry.value <= 4 ? (retry.value += 1) : es.close();
    };
  };
  return { createEventSourceClient };
};

export { useCryptoStream };
