<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 bg-black bg-opacity-75" @click="handleClose" />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-center">
            <div
              class="mr-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mr-0 sm:h-10 sm:w-10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium dark:text-gray-100">
                Settings
              </h3>
            </div>
          </div>

          <div class="flex flex-col mt-8 w-full">
            <div class="flex flex-col space-y-2 mb-8">
              <p class="dark:text-gray-100">
                Min. transaction amount to notify
              </p>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div
                  class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                >
                  <span class="text-gray-100"> $ </span>
                </div>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  class="bg-gray-800 border border-gray-800 text-gray-100 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 rounded"
                  :value="minAmountToNotify"
                  @change="handleMinAmountToNotify"
                />
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <p
                    class="text-gray-100 sm:text-sm rounded-md h-full py-3 pr-2"
                  >
                    USD
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full flex items-center justify-between">
              <p class="dark:text-gray-100">Allow sound notifications</p>
              <div
                class="flex justify-between items-center"
                @click="handleAllowNotification"
              >
                <div
                  class="w-10 h-5 flex items-center bg-gray-300 rounded-full p-0 duration-300 ease-in-out"
                  :class="{ 'bg-green-400': allowSoundNotification }"
                >
                  <div
                    class="bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out"
                    :class="{ 'translate-x-6': allowSoundNotification }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-gray-900 bg-opacity-80 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
        >
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-700 text-base font-medium text-indigo-100 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="handleSave"
            ref="cancelButtonRef"
          >
            Save
          </button>
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-gray-800 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="handleClose"
            ref="cancelButtonRef"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
import { useSettings } from "@/hooks/use-settings";

export default {
  name: "SettingsModal",

  props: ["shouldOpen"],

  setup(props, context) {
    const store = useStore();
    const open = ref(false);
    const allowSoundNotification = ref(
      store.state.crypto.allowSoundNotification
    );
    const minAmountToNotify = ref(store.state.crypto.amountToAlert);
    const { setSettingsToLocalStorage } = useSettings();

    onMounted(() => {
      open.value = toRefs(props).shouldOpen.value;
    });

    const handleClose = () => {
      context.emit("settings:modal:close");
    };

    const handleMinAmountToNotify = (e) => {
      minAmountToNotify.value = e.target.value;
    };

    const handleAllowNotification = () => {
      allowSoundNotification.value = !allowSoundNotification.value;
    };

    const handleSave = () => {
      store.commit("crypto/SET_ALLOW_SOUND_NOTIFICATION", {
        allow: allowSoundNotification,
      });
      store.commit("crypto/SET_AMOUNT_TO_ALERT", { amount: minAmountToNotify });
      setSettingsToLocalStorage(
        allowSoundNotification.value,
        minAmountToNotify.value
      );
      handleClose();
    };

    return {
      open,
      handleSave,
      handleClose,
      minAmountToNotify,
      allowSoundNotification,
      handleAllowNotification,
      handleMinAmountToNotify,
    };
  },
};
</script>
