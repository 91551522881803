const crypto = {
  namespaced: true,
  state: {
    transactions: [],
    filteredToken: "All",
    amountToAlert: 0,
    allowSoundNotification: true,
    lastBlock: {
      hex: "-",
      dec: "-",
    },
  },
  mutations: {
    SET_LAST_BLOCK(state, { block }) {
      state.lastBlock = block;
    },
    SET_TRANSACTIONS(state, { transactions }) {
      state.transactions = transactions;
    },
    SET_TRANSACTION(state, { transaction }) {
      state.transactions = [transaction, ...state.transactions];
    },
    SET_FILTERED_TOKEN(state, { token }) {
      state.filteredToken = token;
    },
    SET_AMOUNT_TO_ALERT(state, { amount }) {
      state.amountToAlert = amount;
    },
    SET_ALLOW_SOUND_NOTIFICATION(state, { allow }) {
      state.allowSoundNotification = allow;
    },
  },
  actions: {
    alert(
      { commit, state },
      { type = "live", transaction, ranking = "standard" } = {}
    ) {
      const parsed = JSON.parse(transaction);

      if (
        type === "live" &&
        parsed.dollarAmount >= state.amountToAlert &&
        state.allowSoundNotification
      ) {
        const audio = new Audio(
          `${process.env.VUE_APP_BASE_URL}/live_${ranking}.mp3`
        );
        audio.play();
      }
      commit("SET_TRANSACTION", {
        transaction: parsed,
        type,
      });
    },
  },
};

export { crypto };
