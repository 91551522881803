<style lang="scss" scoped>
.logo {
  font-family: "Dosis", sans-serif;
  text-shadow: 0 0 6px #fff;
  transition: all;
}

.blink {
  animation: blink-animation 2s infinite ease;
}
@keyframes blink-animation {
  0% {
    text-shadow: 0 0 6px #fff;
  }
  50% {
    text-shadow: 0 0 5px #fff;
  }
  100% {
    text-shadow: 0 0 6px #fff;
  }
}
</style>
<template>
  <div class="flex justify-center px-4 sm:px-6 xl:px-8 pt-10 pb-10 flex-col">
    <Settings />
    <div class="flex space-y-7 justify-center flex-col text-center">
      <img
        src="../assets/logo.png"
        alt="Mavenflow"
        class="m-auto"
        width="100"
      />
      <h1 class="logo text-3xl tracking-wider dark:text-gray-100 blink">
        mavenflow
      </h1>
      <p class="text-l dark:text-gray-200">
        Hunt DeFi transactions on the BSC blockchain and find out about unusual
        movements as they happen.
      </p>
    </div>

    <div class="flex justify-center pt-20">
      <TokenFilter />
    </div>

    <div class="flex justify-center pt-10">
      <TokenFlow />
    </div>
  </div>
</template>

<script>
import TokenFilter from "@/components/crypto/token-filter.vue";
import TokenFlow from "@/components/crypto/flow/table.vue";
import Settings from "@/components/crypto/settings/settings.vue";
import { useCryptoStream } from "@/hooks/use-crypto-stream";
import { useCryptoStreamBlock } from "@/hooks/use-crypto-stream-block";
import { useSettings } from "@/hooks/use-settings";
import { CryptoService } from "@/services/crypto";

export default {
  name: "Crypto",
  components: {
    TokenFilter,
    TokenFlow,
    Settings,
  },
  async mounted() {
    // Get all historical tranactions.
    const { data: history } = await CryptoService.getCryptoStreamHistorical();
    this.$store.commit("crypto/SET_TRANSACTIONS", { transactions: history });

    // Setup event source client.
    const { createEventSourceClient } = useCryptoStream();
    const {
      createEventSourceClient: createEventSourceClientForBlock,
    } = useCryptoStreamBlock();
    createEventSourceClient(this.$store);
    createEventSourceClientForBlock(this.$store);

    // Get & sync persistent settings.
    const { getSettingsFromLocalStorage } = useSettings(this.$store);
    getSettingsFromLocalStorage();
  },
};
</script>
