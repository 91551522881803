<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<template>
  <div class="flex items-center justify-between mb-2">
    <transition name="fade">
      <p class="text-sm font-mono text-gray-400">
        Last block hunted: {{ lastBlockText }}
      </p>
    </transition>
    <transition name="fade">
      <p v-if="filteredToken !== 'All'" class="text-sm font-mono text-gray-400">
        Buy to Sell Ratio:
        <span
          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
          :class="buyToSellRatioClasses"
        >
          {{ (buyToSellRatio * 100).toFixed(2) }}%
        </span>
      </p>
    </transition>
  </div>
</template>

<script>
import { computed, toRefs, ref, watchEffect, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: ["transactions"],
  setup(props) {
    const { transactions } = toRefs(props);
    const store = useStore();
    const lastBlockText = ref("-");
    const filteredToken = computed(() => store.state.crypto.filteredToken);
    const buyToSellRatio = ref(0);
    const buyToSellRatioClasses = ref([]);

    watch(
      () => store.state.crypto.lastBlock,
      (current, prev) => {
        lastBlockText.value = `${current.dec} `;
        if (Number(prev.dec)) {
          lastBlockText.value = `${current.dec} (Δ ${current.dec - prev.dec})`;
        }
      }
    );

    watchEffect(() => {
      if (filteredToken.value != "All") {
        const transactionTypes = transactions.value
          .filter((t) => !!Number(t.dollarAmount))
          .reduce(
            (carry, item) => {
              // Hold all the values we need in one array: [allValues, bought, sold?]
              carry[0] += item.dollarAmount;
              if (item.to.token === filteredToken.value) {
                carry[1] += item.dollarAmount;
              }
              // TODO: Useful if we ever want to know the SellToBuy ratio.
              // if (item.from.token === filteredToken.value) {
              //   carry[2] += item.dollarAmount;
              // }
              return carry;
            },
            [0, 0]
          );
        buyToSellRatio.value = transactionTypes[1] / transactionTypes[0];
      }
    });

    watch(buyToSellRatio, (current, prev) => {
      if (current != prev) {
        if (buyToSellRatio.value >= 0.5) {
          buyToSellRatioClasses.value = ["bg-green-100", "text-green-800"];
        } else {
          buyToSellRatioClasses.value = ["bg-red-100", "text-red-800"];
        }
      }
    });

    return {
      lastBlockText,
      filteredToken,
      buyToSellRatio,
      buyToSellRatioClasses,
    };
  },
};
</script>
