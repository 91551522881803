<style lang="scss" scoped>
.whale {
  border-left-width: 8px;
  border-left-color: rgba(165, 180, 252) !important;
  &-buy {
    border-left-color: rgb(110, 231, 183) !important;
  }
  &-sell {
    border-left-color: rgb(252, 165, 165) !important;
  }
}
</style>
<template>
  <div class="sm:w-3/4 md:w-3/4 xl:w-1/2 w-full">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <Header :transactions="transactions" />
        <div class="shadow overflow-scroll rounded-lg max-h-screen">
          <table class="w-full">
            <thead class="bg-gray-700">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                >
                  From
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                >
                  To
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                >
                  $ value
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                >
                  Time
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                >
                  Tx
                </th>
              </tr>
            </thead>
            <tbody
              class="bg-white divide y-divide-gray-200"
              v-if="!transactions.length"
            >
              <tr>
                <td></td>
                <td></td>
                <td class="flex flex-col items-center space-y-3.5 py-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-12 w-12 text-purple-800 text-center animate-pulse"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                  </svg>
                  <div class="text-sm text-gray-900 text-center">
                    Loading... <br />
                    Hang tight!
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tbody class="bg-white divide-y divide-gray-200" v-else>
              <tr
                v-for="transaction in transactions"
                :key="transaction.txHash + Math.random()"
                :class="isWhale(transaction)"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ transaction.from?.amount }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ transaction.from?.token }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ transaction.to?.amount }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ transaction.to?.token }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                    :class="getTransactionTypeClasses(transaction.from.token)"
                  >
                    {{
                      transaction?.dollarAmount !== "-"
                        ? `$${transaction?.dollarAmount?.toFixed(2)}`
                        : transaction?.dollarAmount
                    }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ formatTimeStamp(transaction.timestamp) }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium"
                >
                  <a
                    :href="'https://bscscan.com/tx/' + transaction?.txHash"
                    class="text-indigo-600 hover:text-indigo-900"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {{ transaction?.txHash.substring(0, 6) }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Header from "@/components/crypto/flow/header";
import { parseFromTimeZone } from "date-fns-timezone";
import { format } from "date-fns";

export default {
  setup() {
    const store = useStore();
    const transactions = computed(() => store.state.crypto.transactions);
    const filteredToken = computed(() => store.state.crypto.filteredToken);
    const amountToAlert = computed(() => store.state.crypto.amountToAlert);
    const filteredTransactions = ref([]);

    // Watch for side effects & retrigger mutations.
    // TODO: Improve this logic.
    watch(
      () => store.state.crypto.filteredToken,
      (current, prev) => {
        if (current !== prev) {
          if (current !== "All") {
            filteredTransactions.value = transactions.value.filter((t) =>
              [t.from.token, t.to.token].includes(current)
            );
          } else {
            filteredTransactions.value = [];
          }
        }
      }
    );

    watch(
      () => store.state.crypto.transactions,
      (current, prev) => {
        if (current !== prev && filteredToken.value !== "All") {
          filteredTransactions.value = transactions.value.filter((t) =>
            [t.from.token, t.to.token].includes(filteredToken.value)
          );
        }
      }
    );

    // Get $ amount classes.
    const getTransactionTypeClasses = (from) => {
      if (!filteredTransactions.value.length) {
        return ["bg-indigo-100", "text-indigo-800"];
      }
      return filteredToken.value === from
        ? ["bg-red-100", "text-red-800"]
        : ["bg-green-100", "text-green-800"];
    };

    const isWhale = (tx) => {
      if (tx.dollarAmount >= amountToAlert.value) {
        if (!filteredTransactions.value.length) {
          return ["whale"];
        } else {
          if (tx.from.token === filteredToken.value) {
            return ["whale", "whale-sell"];
          } else return ["whale", "whale-buy"];
        }
      }
      return [];
    };

    const formatTimeStamp = (timestamp) => {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const parsed = parseFromTimeZone(timestamp, { timeZone: tz });
      return format(parsed, "HH:mm:ss");
    };

    return {
      //TODO: Fix this bandaid (.slice(0, 350)).
      transactions: computed(() =>
        filteredTransactions.value.length
          ? filteredTransactions.value.slice(0, 350)
          : store.state.crypto.transactions.slice(0, 350)
      ),
      getTransactionTypeClasses,
      isWhale,
      formatTimeStamp,
    };
  },

  components: {
    Header,
  },
};
</script>
