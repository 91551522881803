<template>
  <Listbox as="div" class="sm:w-3/4 md:w-3/4 xl:w-1/2 w-full">
    <ListboxLabel class="block text-sm font-medium text-gray-400">
      Filter by token
    </ListboxLabel>
    <div class="mt-1 relative">
      <ListboxButton
        class="relative w-full bg-gray-800 border border-gray-800 rounded-md shadow-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <span class="flex items-center">
          <span class="ml-3 block truncate text-gray-100">{{
            filteredToken
          }}</span>
        </span>
        <span
          class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 9l4-4 4 4m0 6l-4 4-4-4"
            />
          </svg>
        </span>
      </ListboxButton>

      <transition
        enter-active-class="transition duration-200 linear"
        enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-200"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 w-full bg-gray-800 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <ListboxOption
            as="template"
            v-for="token in tokens"
            :key="token"
            :value="token"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-white bg-indigo-600' : 'text-gray-800',
                'cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
              @click="handleFilter(token)"
            >
              <div class="flex items-center">
                <span
                  class="text-gray-100"
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block truncate',
                  ]"
                >
                  {{ token }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
  },

  setup() {
    const store = useStore();
    const tokens = computed(() => {
      const all = store.state?.crypto?.transactions
        .map((t) => [t.from.token, t.to.token])
        .flat(Infinity)
        .sort();
      return ["All", ...new Set(all)];
    });
    const test = computed(() => {
      return store.state?.crypto?.test;
    });
    const filteredToken = computed(() => store.state?.crypto?.filteredToken);

    const handleFilter = (token) => {
      store.commit("crypto/SET_FILTERED_TOKEN", { token });
    };

    return {
      tokens,
      filteredToken,
      test,
      handleFilter,
    };
  },
};
</script>
