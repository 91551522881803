const tickers = {
  namespaced: true,
  state: {
    tickers: [],
  },
  mutations: {
    SET_TICKER(state, ticker) {
      state.tickers = [ticker, ...state.tickers];
    },
  },
  actions: {
    alert({ commit }, { type = "live", ticker, ranking = "standard" } = {}) {
      if (type === "live") {
        const audio = new Audio(
          `http://107.191.41.163/img/live_${ranking}.mp3`
        );
        audio.play();
      }
      commit("SET_TICKER", JSON.parse(ticker));
    },
  },
};

export { tickers };
